import {
  createDataEvent,
  createEvent,
  EventActionType,
} from '../../helpers/eventCreator';
import DeliveryOption from '../../Models/DeliveryOption';
import { PackageAction } from './packageReducer';
import RequestTypes from '../../enums/RequestTypes';
import { GeocodeDoneInvokeEvent } from '../../Components/AddressAutocomplete/addressAutocompleteMachine';
import { ShippingAddressesContext } from './Components/ShippingAddresses/verifyAddressesMachine';
import { PartnerInformation } from '../../Models/PartnerInformation';
import { ChangeEvent } from 'react';

export const DeliveryEvents = {
  changePackage: (action: PackageAction) =>
    createDataEvent('CHANGE_PACKAGES', action),

  clearPackages: () => createEvent('CLEAR_PACKAGES'),

  addressVerified: (ctx: ShippingAddressesContext) =>
    createDataEvent('ADDRESS_VERIFIED', ctx),

  setPartnerInfo: (partnerInfo: PartnerInformation) =>
    createDataEvent('SET_PARTNERINFO', partnerInfo),

  selectOption: (deliveryOption: DeliveryOption) =>
    createDataEvent('SELECT_OPTION', deliveryOption),

  selectRequestType: (requestType: RequestTypes | string) =>
    createDataEvent('SELECT_REQUESTTYPE', requestType as RequestTypes),

  selectDate: (date: string) => createDataEvent('SELECT_DATE', date),

  selectTime: (e: ChangeEvent<HTMLInputElement>) =>
    createDataEvent('SELECT_TIME', e.target.value),

  blurTime: () => createEvent('BLUR_TIME'),

  loadAlternatives: () => createEvent('LOAD_ALTERNATIVES'),

  done: () => createEvent('DELIVERY_DONE'),

  setLoggedIn: () => createEvent('SET_LOGGED_IN'),

  createSupportTicket: () => createEvent('CREATE_SUPPORT_TICKET'),
};

export type TDeliveryEvents =
  | EventActionType<typeof DeliveryEvents>
  | GeocodeDoneInvokeEvent;
