import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import './styles/index.css';
import ReactModal from 'react-modal';
import { SendPackage } from './SendPackage';
import { getUserLanguage } from './mappers/jenaMappers';

ReactModal.setAppElement('#root');

console &&
  console.log(
    `Build id ${process.env.REACT_APP_BUILD_ID}, number ${process.env.REACT_APP_BUILD_NUMBER}`
  );

ReactDOM.render(
  <React.StrictMode>
    <SendPackage
      appMode="standalone"
      apiBaseUrl={process.env.REACT_APP_JENA_BASE_URL!}
      basePath={'/'}
      apiToken={process.env.REACT_APP_JENA_TOKEN!}
      language={getUserLanguage()}
      googleKey={process.env.REACT_APP_GOOGLE_KEY!}
      netsCheckout={process.env.REACT_APP_NETS_CHECKOUT_SCRIPT!}
      googleGtmId={process.env.REACT_APP_GTM_ID}
    />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
