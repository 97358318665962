import jenaApi from '../api/jenaApi';
import { useContext, useMemo } from 'react';
import ConfigContext from '../context/ConfigContext';
import { DeliveryContext } from '../Pages/Delivery/deliveryMachine';
import {
  contextToBookDef,
  contextToCreateAwbRequest,
  contextToDeliveryAlternatives,
  createGetCityInfoRequest,
  customerTransportInfoToPartnerInfo,
  loginResponseToUser,
  registerContextToSaveRegistrationRequest,
} from '../mappers/jenaMappers';
import IAddress from '../Models/Address';
import { SendPackageContext } from '../Machines/sendPackageMachine';
import { UserContext } from '../context/UserContext';
import { IRegisterContext } from '../Pages/Register/RegisterMachine';
import { User } from '../Models/User';
import { ChangePasswordRequest } from '../Models/jena/request/ChangePasswordRequest';

const useJenaApi = () => {
  const { apiBaseUrl, apiToken } = useContext(ConfigContext);
  const { sessionToken, countries } = useContext(UserContext);
  const api = useMemo(
    () =>
      jenaApi({
        baseUrl: apiBaseUrl,
        sessionToken,
        token: apiToken,
      }),
    [apiBaseUrl, sessionToken, apiToken]
  );
  return {
    getTransportAlternatives: (deliveryContext: DeliveryContext, user?: User) =>
      api.getTransportAlternatives(
        contextToDeliveryAlternatives(deliveryContext, user)
      ),
    /**
     * Gets the responsible partner for the selected shipping countries
     * @param {IAddress} shipper
     * @param {IAddress} consignee
     */
    getCustomerTransportInfo: (shipper: IAddress, consignee: IAddress) =>
      api
        .getCustomerTransportInfo({
          fromcountryid: shipper.countryId,
          tocountryid: consignee.countryId,
          checkcredit: 1,
        })
        .then(customerTransportInfoToPartnerInfo),
    getCustomerTransportInfoForCountry: (countryId: number) =>
      api
        .getCustomerTransportInfo({
          fromcountryid: countryId,
          tocountryid: countryId,
          checkcredit: 1,
        })
        .then(customerTransportInfoToPartnerInfo),
    getShop: api.getShop,
    getInsurance: api.getInsurance,
    verifyAddress: api.verifyAddress,
    saveAddress: api.saveAddress,
    bookDef: api.bookDef,
    listaddress: (searchstring: string, user?: User) =>
      api.listaddress({ searchstring, userid: user?.id ?? '' }, countries),
    saveCustoms: api.saveCustoms,
    createOrder: (ctx: SendPackageContext, user?: User) =>
      api.bookDef(contextToBookDef(ctx, user)),

    createAwb: (context: SendPackageContext) =>
      api
        .createAwb(contextToCreateAwbRequest(context))
        .then((response) => response.awbnbr),

    getConsignmentInfo: api.getConsignmentInfo,

    login: (username: string, password: string) =>
      api.login({ user: username, pwd: password }).then((response) => ({
        user: loginResponseToUser(response),
        sessionToken: response.sessiontoken,
      })),

    resetPassword: (email: string) => api.resetPassword({ email }),
    changePwd: (data: ChangePasswordRequest) => api.changePwd(data),
    //
    // getUser: () => api.getUserInfo().then(customerInfoResponseToUser),
    getCountries: () => api.getCountries(),
    listPackageTypes: () => api.listPackageTypes(),
    getCityInfo: (countryId: number, postalCode: string) =>
      api
        .getCityInfo(createGetCityInfoRequest(countryId, postalCode))
        .then((response) => response),
    getRegistration: (
      partnerCompanyId: string,
      orgNumber?: string,
      customerNumber?: string
    ) => {
      return api.getRegistration({
        customer_customernbr: customerNumber,
        customer_organizationnbr: orgNumber,
        partnercompid: partnerCompanyId, // '21',
        customer_customerchoice: customerNumber ? '1' : '2',
        customer_customertype: orgNumber ? '2' : '1',
      });
    },
    saveRegistration: (ctx: IRegisterContext) => {
      return api.saveRegistration(
        registerContextToSaveRegistrationRequest(ctx)
      );
    },
    generateSupportTicket: api.generateSupportTicket,
    verifyCampaignCode: (ctx: SendPackageContext, user?: User) =>
      api.verifyCampaignCode({
        code: ctx.campaignCode!,
        customerproducts: ctx.deliveryOption?.CustomerProductId!,
        custorgid: user?.customerOrgId || '',
        custorgunitid: '0',
        custpersonid: user?.customerPersonId || '',
        requestid: ctx.requestId || '',
      }),
  };
};

export default useJenaApi;
