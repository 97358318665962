import { assign } from 'xstate';
import { TPackageInformationEvents } from './PackageInformationEvents';
import { MachineConfig } from 'xstate/lib/types';
import { SendPackageContext, TGuards } from '../../Machines/sendPackageMachine';
import { Currency } from '../../enums/Currency';
import virtualPageview from '../../virtualPageview';

export interface InsuranceInfo {
  maxValue: number;
  minPrice: number;
  currency: keyof typeof Currency;
  rate: number;
  vatable: boolean;
  vatRate: number;
}

export interface PackageInformationContext {
  acceptedAirlineTerms: boolean;
  contents?: string;
  customsRequired: boolean;
  useInsurance: boolean;
  insuranceValue?: number;
  insurance?: InsuranceInfo;
}

export enum PackageGuards {
  notAcceptedDangerousGoods = 'notAcceptedDangerousGoods',
  invalidInsurance = 'invalidInsurance',
  invalidContents = 'invalidContents',
  validPackageInformationPage = 'validPackageInformationPage',
}
const inputStates = (invalidGuard: PackageGuards) => ({
  initial: 'init',
  states: {
    init: {},
    edit: {
      always: [
        {
          cond: invalidGuard,
          target: 'error',
        },
        { target: 'valid' },
      ],
    },
    valid: {},
    error: {},
  },
});
const packageInformationMachine: MachineConfig<
  SendPackageContext, //PackageInformationContext,
  any,
  TPackageInformationEvents
> = {
  id: 'packageInformation',
  initial: 'init',
  states: {
    init: {
      entry: [
        'scrollToTop',
        assign({
          acceptedAirlineTerms: (ctx, e) =>
            ctx.userLoggedIn &&
            ctx.deliveryOption?.MethodOfTransport !== 'Flight' &&
            localStorage.getItem('acceptedAirlineTerms') === ctx.userId
              ? true
              : false,
        }),
        () => virtualPageview('/package-content', 'Package content'),
      ],
      invoke: {
        id: 'getInsurance',
        src: 'getInsurance',
        onError: 'error',
        onDone: {
          target: 'editing',
          actions: assign({
            insurance: (_, event) => event.data,
          }),
        },
      },
    },
    error: {},

    editing: {
      type: 'parallel',
      states: {
        contents: inputStates(PackageGuards.invalidContents),
        acceptTerms: inputStates(PackageGuards.notAcceptedDangerousGoods),
        value: inputStates(PackageGuards.invalidInsurance),
        // history: {
        //   type: 'history',
        //   history: 'deep',
        // },
      },
    },
    valid: {},
  },
  on: {
    CHANGE_CONTENTS: [
      {
        target: '#packageInformation.editing.contents.edit',
        actions: assign({
          contents: (_, event) => event.data,
        }),
      },
    ],
    CHANGE_ACCEPTED_TERMS: [
      {
        actions: assign({
          acceptedAirlineTerms: (_, event) => event.data,
        }),
        target: '#packageInformation.editing.acceptTerms.edit',
      },
    ],
    CHANGE_INSURANCE_VALUE: [
      {
        target: '#packageInformation.editing.value.edit',
        actions: assign({
          insuranceValue: (_, { data }) => parseInt(data),
        }),
      },
    ],
    CHANGE_USE_INSURANCE: [
      {
        actions: assign({
          useInsurance: (_, { data }) => data,
          insuranceValue: (_, { data }) => 0,
        }),
      },
    ],
    DONE_PACKAGE_INFO: [
      {
        cond: PackageGuards.validPackageInformationPage,
        target: '#senderAndConsignee',
      },
      {
        target: [
          '#packageInformation.editing.value.edit',
          '#packageInformation.editing.acceptTerms.edit',
          '#packageInformation.editing.contents.edit',
        ],
      },
    ],
  },
};

export const packageInformationGuards: TGuards<PackageGuards> = {
  [PackageGuards.notAcceptedDangerousGoods]: ({ acceptedAirlineTerms }) =>
    !acceptedAirlineTerms,

  [PackageGuards.invalidContents]: (ctx) => (ctx.contents?.length || 0) < 1,

  [PackageGuards.invalidInsurance]: (ctx) => {
    return !(
      ctx.useInsurance &&
      (ctx.insuranceValue || 0) <= (ctx.insurance?.maxValue || 0) &&
      (ctx.insuranceValue || 0) > 0
    );
  },
  validPackageInformationPage: (ctx) =>
    ctx.acceptedAirlineTerms &&
    !((ctx.contents?.length || 0) < 1) &&
    (!ctx.useInsurance ||
      ((ctx.insurance?.maxValue || 0) >= (ctx.insuranceValue || 0) &&
        (ctx.insuranceValue || 0) > 0)),
};

export default packageInformationMachine;
