import { TransportAlternativeResponse } from '../Models/jena/response/TransportAlternativeResponse';
import { TransportAlternativeRequest } from '../Models/jena/request/TransportAlternativeRequest';
import IVerifyAddressRequest from '../Models/jena/request/IVerifyAddressRequest';
import { BookDefRequest } from '../Models/jena/request/BookDefRequest';
import { GetConsignmentInfoResponse } from '../Models/jena/response/GetConsignmentInfoResponse';
import { BookDefResponse } from '../Models/jena/response/BookDefResponse';
import { CreateAwbRequest } from '../Models/jena/request/CreateAwbRequest';
import { CreateAwbResponse } from '../Models/jena/response/CreateAwbResponse';
import { VerifyAddressResponse } from '../Models/jena/response/VerifyAddressResponse';
import { LoginRequest } from '../Models/jena/request/LoginRequest';
import { LoginResponse } from '../Models/jena/response/LoginResponse';
import { GetInsuranceRequest } from '../Models/jena/request/GetInsuranceRequest';
import { GetInsuranceResponse } from '../Models/jena/response/GetInsuranceResponse';
import { RemoveWebAddressRequest } from '../Models/jena/request/RemoveWebAddressRequest';
import { RemoveWebAddressResponse } from '../Models/jena/response/RemoveWebAddressResponse';
import { GetCustomerTransportInfoRequest } from '../Models/jena/request/GetcustomerTransportInfoRequest';
import { GetCustomerTransportInfoResponse } from '../Models/jena/response/GetCustomerTransportInfoResponse';
import { GetRegistrationRequest } from '../Models/jena/request/GetRegistrationRequest';
import { GetRegistrationResponse } from '../Models/jena/response/GetRegistrationResponse';
import { SaveRegistrationRequest } from '../Models/jena/request/SaveRegistrationRequest';
import { SaveRegistrationResponse } from '../Models/jena/response/SaveRegistrationResponse';
import { parseISO } from 'date-fns';
import { GetCustomerInfoResponse } from '../Models/jena/response/GetCustomerInfoResponse';
import {
  GetCountriesResponse,
  ListCountry,
} from '../Models/jena/response/GetCountriesResponse';
import { GetShopResponse } from '../Models/jena/response/GetShopResponse';
import {
  countryToLanguage,
  getShopToShop,
  listAddressToAddress,
  listcampaignToCampaign,
} from '../mappers/jenaMappers';
import { SaveWebAddressRequest } from '../Models/jena/request/SaveWebAddressRequest';
import { SaveWebAddressResponse } from '../Models/jena/response/SaveWebAddressResponse';
import { CreateGetCityInfoRequest } from '../Models/jena/request/GetCityInfo';
import {
  CreateGetCityInfoResponse,
  ListCity,
} from '../Models/jena/response/CreateGetCityInfoResponse';
import { ResetPasswordRequest } from '../Models/jena/request/ResetPasswordRequest';
import { ChangePasswordRequest } from '../Models/jena/request/ChangePasswordRequest';
import { GenerateSupportTicketRequest } from '../Models/jena/request/GenerateSupportTicketRequest';
import { GenerateSupportTicketResponse } from '../Models/jena/response/GenerateSupportTicketResponse';
import { VerifyCampaignCodeRequest } from '../Models/jena/request/VerifyCampaignCodeRequest';
import { VerifyCampaignCodeResponse } from '../Models/jena/response/VerifyCampaignCodeResponse';
import DeliveryOption from '../Models/DeliveryOption';
import {
  JenaPackagetype,
  ListPackageTypesResponse,
  Packagetype,
} from '../Models/jena/request/ListPackageTypesResponse';
import { Translation } from '../Models/jena/Translation';
import { ListAddressRequest } from '../Models/jena/request/ListAddressRequest';
import { ListAddressResponse } from '../Models/jena/response/ListAddressResponse';
import { SaveCustomsRequest } from '../Models/jena/request/SaveCustomsRequest';
import SaveCustomsResponse from '../Models/jena/response/SaveCustomsResponse';

interface IApiOptions {
  baseUrl: string;
  token: string;
  sessionToken?: string;
}

export interface IRequestOptions<TReturnData> {
  responseHandler: (response: Response) => Promise<TReturnData>;
}
interface JenaResponseEnvelope<TResponseData> {
  result: 'ok';
  response: TResponseData;
}
interface JenaErrorEnvelope {
  result: 'error' | 'ok';
  error: {
    'error-code': string;
    'error-text': string;
  };
}

const isError = (
  response: JenaErrorEnvelope | JenaResponseEnvelope<any>
): response is JenaErrorEnvelope =>
  response.result === 'error' || 'error' in response;

type HttpMethod = 'GET' | 'POST' | 'DELETE' | 'PUT';

const dateToTimestamp = (date: Date) => Math.floor(date.getTime() / 1000);

const jenaEnvelope =
  (token: string, session?: string) =>
  (action: string, data: any, timestamp: Date = new Date()) => ({
    request: {
      datetime: dateToTimestamp(timestamp),
      action: action,
      data: data,
    },
    authorization: {
      interfacetoken: token,
      //"deviceid": "633",
      sessiontoken: session,
      //"appfunction": "JenaCustomerTool",
      apptype: '2',
      appversion: 11,
    },
  });
const buildURLSearchParams = (data: any): URLSearchParams => {
  const ret = new URLSearchParams();
  Object.keys(data)
    .filter((x) => data[x])
    .forEach((key: string) => {
      if (data[key] instanceof Array) {
        let array: any[] = data[key] as any[];
        for (let i = 0; i < array.length; i++) {
          ret.append(key, array[i]);
        }
      }
      ret.append(key, data[key]);
    });
  return ret;
};

export const parseJson = async (isoResponse: Response) => {
  // Response is unfortunately in iso-8859-1 and needs to be decoded.
  // .json() assumes utf-8 and can't be bothered for anything else.
  // COmmented out for now as response may be in UTF-8 in most cases.
  try {
    return await isoResponse.json();
    /*
    const test = isoResponse.clone();
    console.log(await test.json());
    const isoDecoder = new TextDecoder('iso-8859-1');
    const arrayBuffer = isoDecoder.decode(
      await isoResponse.clone().arrayBuffer()
    );
    return JSON.parse(arrayBuffer);
    */
  } catch {
    // Fallback in case of IE or similar.
    return await isoResponse.json();
  }
};

export const jenaResponseHandler = async <TReturnData>(response: Response) => {
  const jsonResponse = await parseJson(response);
  const data = jsonResponse as
    | JenaResponseEnvelope<TReturnData>
    | JenaErrorEnvelope;

  if (isError(data)) {
    const error = new Error(data.error['error-text']) as Error & {
      response: Response;
    };
    error.response = response;
    throw error;
  } else if ('response' in data) {
    return data.response;
  }
  return {} as unknown as TReturnData;
};

const api = ({ baseUrl, token, sessionToken }: IApiOptions) => {
  const envelope = jenaEnvelope(token, sessionToken);
  const request = async <TReturnData>(
    method: HttpMethod,
    endpoint: string,
    data: any,
    query: any = {},
    options: Partial<IRequestOptions<TReturnData>> = {}
  ): Promise<TReturnData> => {
    const url = `${baseUrl}/${endpoint}`;
    const response = await fetch(url, {
      method,
      body: data
        ? JSON.stringify(data)
        : query
        ? buildURLSearchParams(query)
        : null,
    });

    if (response.status >= 200 && response.status < 300) {
      return options.responseHandler
        ? options.responseHandler(response)
        : jenaResponseHandler<TReturnData>(response);
    }

    const error = new Error(response.statusText) as Error & {
      response: Response;
    };
    error.response = response;
    throw error;
  };

  const post = async <TReturnData>(action: string, data: any) =>
    request<TReturnData>('POST', 'api.cs', envelope(action, data));

  return {
    verifyAddress: async (data: IVerifyAddressRequest) =>
      post<VerifyAddressResponse>('verifyaddress', data),

    getTransportAlternatives: async (
      data: TransportAlternativeRequest
    ): Promise<TransportAlternativeResponse> =>
      request('POST', 'book/webBookTimeAndPrice.cse', undefined, data, {
        responseHandler: (response) =>
          response.json().then((val: TransportAlternativeResponse) => {
            if (
              val.Success.toLowerCase() === 'false' &&
              val.Error !== 'No alternatives returned'
            )
              throw new Error(val.Error);

            return {
              ...val,
              Alternatives: val.Alternatives.map((a: DeliveryOption) => {
                const OriginalPriceIncludingVAT = parseFloat(
                  (a.OriginalPriceIncludingVAT as unknown as string).replace(
                    ',',
                    '.'
                  )
                );
                const OriginalPriceExcludingVAT = parseFloat(
                  (a.OriginalPriceExcludingVAT as unknown as string).replace(
                    ',',
                    '.'
                  )
                );
                const Vat =
                  Math.round(
                    (OriginalPriceIncludingVAT - OriginalPriceExcludingVAT) *
                      100
                  ) / 100;
                return {
                  ...a,
                  PickupDateTime: parseISO(
                    a.PickupDateTime as unknown as string
                  ),
                  DeliveryDateTime: parseISO(
                    a.DeliveryDateTime as unknown as string
                  ),
                  PriceExcludingVAT: parseFloat(
                    (a.PriceExcludingVAT as unknown as string).replace(' ', '')
                  ),
                  PriceIncludingVAT: parseFloat(
                    (a.PriceIncludingVAT as unknown as string).replace(' ', '')
                  ),
                  OriginalPriceIncludingVAT,
                  OriginalPriceExcludingVAT,
                  Vat,
                };
              }),
            };
          }),
      }),

    getConsignmentInfo: async (id: string) =>
      post<GetConsignmentInfoResponse>('getconsignmentinfo', {
        consignmentid: id,
      }),

    bookDef: async (data: BookDefRequest) =>
      post<BookDefResponse>('bookdef', data),

    saveCustoms: async (data: SaveCustomsRequest) =>
      post<SaveCustomsResponse>('savecustoms', data),

    createAwb: async (data: CreateAwbRequest) =>
      post<CreateAwbResponse>('createawb', data),

    login: async (data: LoginRequest) => post<LoginResponse>('login', data),

    resetPassword: async (data: ResetPasswordRequest) =>
      post('resetPassword', data),

    changePwd: async (data: ChangePasswordRequest) => post('changePwd', data),

    getUserInfo: async () =>
      post<GetCustomerInfoResponse>('getcustomerinfo', {}),

    listPackageTypes: async () =>
      post<ListPackageTypesResponse>(
        sessionToken ? 'listpackagetypes' : 'listpackagetypes',
        {}
      ).then((response) =>
        [...response.packagetypes]
          .filter((pt): pt is JenaPackagetype => typeof pt !== 'string')
          .map(
            (pt) =>
              ({
                ...pt,
                defaultheight: !!pt.defaultheight
                  ? Number(pt.defaultheight)
                  : undefined,
                defaultlength: !!pt.defaultlength
                  ? Number(pt.defaultlength)
                  : undefined,
                defaultweight: !!pt.defaultweight
                  ? Number(pt.defaultweight)
                  : undefined,
                defaultwidth: !!pt.defaultwidth
                  ? Number(pt.defaultwidth)
                  : undefined,
                maxheight: Number(pt.maxheight),
                maxlength: Number(pt.maxlength),
                maxweight: Number(pt.maxweight),
                maxwidth: Number(pt.maxwidth),

                translations: pt.translations
                  .filter((t): t is Translation => typeof t !== 'string')
                  .map((x) => ({
                    language: countryToLanguage(x.language),
                    translation: x.translation,
                  })),
              } as Packagetype)
          )
      ),

    getInsurance: async (data: GetInsuranceRequest) =>
      post<GetInsuranceResponse>('getinsurance', data),

    getCustomerTransportInfo: async (data: GetCustomerTransportInfoRequest) =>
      post<GetCustomerTransportInfoResponse>('getcustomertransportinfo', data),

    getCountries: async () =>
      post<GetCountriesResponse>('listcountryweb', {}).then((response) =>
        [...response.listcountry].filter(
          (c): c is ListCountry => typeof c !== 'string'
        )
      ),

    /**
     * @deprecated getShop is no longer used
     */
    getShop: async () =>
      post<GetShopResponse>('getshop', {}).then(getShopToShop),

    removeUserAddress: async (data: RemoveWebAddressRequest) =>
      post<RemoveWebAddressResponse>('removewebaddress', data),

    saveAddress: async (data: SaveWebAddressRequest) =>
      post<SaveWebAddressResponse>('savewebaddress', data),

    getRegistration: async (data: GetRegistrationRequest) =>
      post<GetRegistrationResponse>('showwebregistration', data),

    saveRegistration: async (data: SaveRegistrationRequest) =>
      post<SaveRegistrationResponse>('saveregistration', data),

    getCityInfo: async (data: CreateGetCityInfoRequest) =>
      post<CreateGetCityInfoResponse>('getcityinfo', data).then((response) =>
        [...response.listcity].filter(
          (c): c is ListCity => typeof c !== 'string'
        )
      ),

    generateSupportTicket: async (data: GenerateSupportTicketRequest) =>
      post<GenerateSupportTicketResponse>('generateSupportTicket', data),

    verifyCampaignCode: async (data: VerifyCampaignCodeRequest) =>
      post<VerifyCampaignCodeResponse>('verifycampaigncode', data).then(
        listcampaignToCampaign
      ),

    listaddress: async (data: ListAddressRequest, countries: ListCountry[]) =>
      post<ListAddressResponse>('listaddress', data).then(
        listAddressToAddress(countries)
      ),
  };
};

export default api;
