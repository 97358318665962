import { useContext, useState, useRef } from 'react';

/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import ConfigContext from './../context/ConfigContext';
import { LanguageCode, TLanguageCode } from '../translations/TranslationType';
import {
  IconChevronDown,
  IconDK,
  IconFI,
  IconGB,
  IconNO,
  IconSE,
} from '../svg';
import useClickOutsideHandler from '../hooks/useClickOutsideHandler';

const getLanguageIcon = (lang: TLanguageCode): React.ReactNode => {
  switch (lang) {
    case 'sv-SE':
      return <IconSE css={[tw`w-5 h-5`]} />;
    case 'nb-NO':
      return <IconNO css={[tw`w-5 h-5`]} />;
    case 'da-DK':
      return <IconDK css={[tw`w-5 h-5`]} />;
    case 'fi-FI':
      return <IconFI css={[tw`w-5 h-5`]} />;
    default:
      return <IconGB css={[tw`w-5 h-5`]} />;
  }
};

export default function SwitchLanguage() {
  const { setLanguage, language } = useContext(ConfigContext);
  const handleChangeLanguage = (lang: TLanguageCode): void => {
    setLanguage(lang);
    setShowSwitcher(false);
  };
  const [showSwitcher, setShowSwitcher] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useClickOutsideHandler(ref, () => setShowSwitcher(false));

  return (
    <div className="relative" ref={ref}>
      <button
        key={LanguageCode[language]}
        css={[tw`flex items-center`]}
        onClick={() => {
          setShowSwitcher((v) => !v);
        }}
      >
        {getLanguageIcon(language)}
        <IconChevronDown tw="w-5 h-6" />
      </button>
      <div
        css={[
          tw`absolute right-0 top-6 border rounded flex flex-col bg-white z-30 shadow`,
          showSwitcher ? '' : tw`hidden`,
        ]}
      >
        {Object.keys(LanguageCode).map((k: TLanguageCode | string) => {
          const key = k as TLanguageCode;

          return (
            <button
              key={LanguageCode[key]}
              css={[tw`flex items-center space-x-2 p-2 px-3 hover:bg-gray-100`]}
              onClick={() => {
                handleChangeLanguage(key);
              }}
            >
              {getLanguageIcon(key)} <span tw="block">{LanguageCode[key]}</span>
            </button>
          );
        })}
      </div>
    </div>
  );
}
