import { ConfigProps, ConfigProvider } from './context/ConfigContext';
import { UserProvider } from './context/UserContext';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { useEffect } from 'react';
import 'react-use-what-input';
import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';

interface SendPackageConfig extends ConfigProps {
  googleKey: string;
  netsCheckout: string;
  googleGtmId?: string;
}
export const SendPackage = ({
  googleKey,
  netsCheckout,
  googleGtmId,
  ...config
}: SendPackageConfig) => {
  useEffect(() => {
    if (googleKey && !document.getElementById('googleScript')) {
      const googleScript = document.createElement('script');
      googleScript.id = 'googleScript';
      googleScript.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places&callback=initMap`;
      googleScript.async = true;
      googleScript.defer = true;
      googleScript.setAttribute('data-cookieconsent', 'ignore');
      document.body.appendChild(googleScript);
    }
  }, [googleKey]);
  useEffect(() => {
    if (netsCheckout && !document.getElementById('netsCheckout')) {
      const script = document.createElement('script');
      script.id = 'netsCheckout';
      script.src = netsCheckout;
      script.async = true;
      script.defer = true;
      script.setAttribute('data-cookieconsent', 'ignore');
      document.body.appendChild(script);
    }
  }, [netsCheckout]);
  useEffect(() => {
    if (googleGtmId && !document.getElementById('gtmScript')) {
      const s = document.createElement('script');
      s.id = 'gtmScript';
      s.src = `https://www.googletagmanager.com/gtm.js?id=${googleGtmId}`;
      s.async = true;
      s.defer = true;
      document.body.appendChild(s);
      const w = window;
      const l = 'dataLayer';
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });
    }
  }, [googleGtmId]);
  return (
    <ConfigProvider {...config}>
      <UserProvider>
        <BrowserRouter basename={config.basePath}>
          <App />
        </BrowserRouter>
      </UserProvider>
    </ConfigProvider>
  );
};

export const MountApp = ({
  element,
  ...config
}: SendPackageConfig & { element: HTMLDivElement }) => {
  if (element) ReactModal.setAppElement(element as HTMLDivElement);

  ReactDOM.render(<SendPackage {...config} />, element);
};
