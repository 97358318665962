/** @jsxImportSource @emotion/react */
import { useContext, Fragment } from 'react';
import tw from 'twin.macro';
import { LoginPage } from '../../Pages';
import { Button } from '../../Components/Base';
import { H2 } from '../../Components/Typograhy';
import { useUser } from '../../hooks/useUser';
import { UserContext } from '../../context/UserContext';
import useTranslation from '../../hooks/useTranslation';
import { useHistory } from 'react-router-dom';

const Profile = () => {
  const { user, logout } = useUser();
  const translations = useTranslation();

  const history = useHistory();
  return (
    <div css={tw`container mx-auto pt-10`}>
      <div css={tw`row max-w-xl`}>
        <div css={tw`col-12`}>
          <H2>{translations.welcome}</H2>
          <p>
            {translations.youAreloggedInAs}
            {` ${user?.firstName || ''} ${user?.lastName || ''}`}
          </p>
          {user?.orgName && <p>{user?.orgName}</p>}
          <address tw={'mt-2'}>
            {`${user?.streetAddress || ''} ${user?.streetNumber || ''}`} <br />{' '}
            {`${user?.postcode || ''} ${user?.city || ''}`}
          </address>
          <p tw={'mt-2'}>
            {`${translations.registerPage.fields.customerNumber}: ${user?.customerNumber}`}
          </p>
          <div tw={'flex justify-end mt-10'}>
            <Button
              onClick={() => {
                logout();
              }}
            >
              {translations.signOut}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                history.push('/');
              }}
              tw={'ml-4'}
            >
              {translations.close}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const UserPage = () => {
  const { isLoggedIn } = useContext(UserContext);

  return <Fragment>{!isLoggedIn ? <LoginPage /> : <Profile />}</Fragment>;
};

export default UserPage;
