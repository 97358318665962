/** @jsxImportSource @emotion/react */
import { useContext, useEffect, useState, Fragment } from 'react';
import { DeliveryContext } from './deliveryMachine';
import tw, { css } from 'twin.macro';
import { useInView } from 'react-intersection-observer';
import { FormLabel, FormSelect } from '../../Components/FormFields';
import { DeliveryAlternatives } from './Components/DeliveryAlternatives';
import { DeliveryEvents, TDeliveryEvents } from './DeliveryEvents';
import RequestTypes from '../../enums/RequestTypes';
import { getDateSelectOptions, IDate } from '../../helpers/dateSelectGenerator';
import useTranslation from '../../hooks/useTranslation';
import { Translations } from '../../translations/TranslationType';
import ShippingAddresses from './Components/ShippingAddresses/ShippingAddresses';
import ConfigContext from '../../context/ConfigContext';
import { StateValue } from 'xstate';
import { useBoundEvents } from '../../hooks/useBoundEvents';
import StepNavigation, {
  StepNavigationNext,
  StepNavigationRow,
} from '../../Components/Base/StepNavigation';
import Bounce from '../../Components/Base/Bounce';
import FormMessage from '../../Components/Base/FormMessage';
import { Packages } from './Components/Packages';
import TimeField from 'react-simple-timefield';
import { OpenHelp } from './Components/OpenHelp';
import useFocusHandler from '../../hooks/useFocusHandler';
import { Context } from '../../Machines/sendPackageMachine';

interface DeliveryPageProps {
  state: Context & DeliveryContext;
  clock?: IDate;
  send: (event: TDeliveryEvents) => void;
  matches: (key: StateValue) => boolean;
}

const DeliveryPage = ({ state, send, matches }: DeliveryPageProps) => {
  const translation: Translations = useTranslation();
  const { language } = useContext(ConfigContext);
  const events = useBoundEvents(DeliveryEvents, send);
  const [bounceRef, inView] = useInView({
    /* Optional options */
    threshold: 0.65,
    delay: 150,
  });
  const [shouldShowNotification, setShouldShowNotification] =
    useState<boolean>(false);
  useEffect(() => {
    if (inView) setShouldShowNotification(false);
  }, [inView]);

  useEffect(() => {
    if (!inView) setShouldShowNotification(state.hasNewAlternatives);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.hasNewAlternatives]);

  useFocusHandler(events.blurTime);

  const {
    shipper,
    consignee,
    time,
    date,
    requestType,
    addressVerified,
    supportTicketId,
    packageTypes,
    packages,
  } = state;
  return (
    <Fragment>
      <div tw={'container mx-auto px-4 pt-12'}>
        <div css={tw`row`}>
          <div tw={'col-12'}>
            <OpenHelp
              hasAlternatives={state.alternatives.length > 0}
              error={matches('supportTicket.error')}
              loading={matches('supportTicket.creating')}
              supportTicketId={supportTicketId}
              label={translation.help}
              onCreateTicket={() => {
                events.createSupportTicket();
              }}
            />
            <h2 css={tw`text-2xl font-semibold mb-4`}>
              {translation.deliveryPage.addressTitle}
            </h2>
          </div>
          <ShippingAddresses
            shipperAddress={shipper}
            consigneeAddress={consignee}
            onVerified={events.addressVerified}
            verified={addressVerified}
          />
          <div tw={'col-12 lg:col-8 mt-8 mb-12'}>
            <div css={tw`relative flex flex-wrap`}>
              <div css={tw`w-full flex sm:w-6/12 mb-4 sm:mb-0 sm:pr-4 `}>
                <div css={tw`flex flex-col w-full`}>
                  <FormSelect
                    name="requestType"
                    label={translation.pickUpAndDelivery}
                    value={requestType}
                    onChange={events.selectRequestType}
                    onBlur={events.blurTime}
                    options={[
                      {
                        label: translation.earliestPickup,
                        value: RequestTypes.EarliestPickup,
                      },
                      {
                        label: translation.latestDelivery,
                        value: RequestTypes.LatestDelivery,
                      },
                    ]}
                  />
                </div>
              </div>
              <div css={tw`w-6/12 sm:w-4/12 pr-4`}>
                <FormSelect
                  label={translation.date}
                  name="date"
                  value={date || ''}
                  onChange={events.selectDate}
                  onBlur={events.blurTime}
                  options={getDateSelectOptions({
                    today: translation.today,
                    tomorrow: translation.tomorrow,
                    locale: language,
                  })}
                />
              </div>

              <div css={tw`w-6/12 sm:w-2/12`}>
                <FormLabel name="time">{translation.time}</FormLabel>
                <TimeField
                  value={time}
                  onChange={events.selectTime}
                  style={{ width: '100%' }}
                  css={[
                    tw`max-w-full block w-full px-2 rounded border border-gray-400 bg-white leading-10 text-base text-center`,
                    matches('editing.time.error') && tw`border-red border`,
                    matches('editing.time.error') &&
                      css`
                        transition: all 250ms ease 0s;
                        &:focus {
                          border-color: rgb(215 53 44 / 82%);
                          box-shadow: 0 1px 1px rgb(215 53 44 / 14%) inset,
                            0 0 8px rgb(215 53 44 / 39%);
                          outline: 0 none;
                        }
                      `,
                  ]}
                />
                {matches('editing.time.error') && (
                  <FormMessage
                    label={translation.timeHasPassed}
                    status={'error'}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Packages
        packages={packages}
        packageTypes={packageTypes}
        updatePackages={events.changePackage}
        errors={[
          'excessiveWeight',
          'excessiveVolume',
          'invalidPackages',
        ].filter(
          (e): e is 'excessiveWeight' | 'excessiveVolume' | 'invalidPackages' =>
            matches(`editing.packages.error.${e}`)
        )}
      />

      {shouldShowNotification && (
        <Bounce label={translation.deliveryPage.selectDeliveryOptions} />
      )}

      <DeliveryAlternatives
        ref={bounceRef}
        alternatives={state.alternatives}
        handleSelect={events.selectOption}
        selected={state.deliveryOption}
        isLoading={matches('loading')}
        noneFound={matches('picking.nodata') || matches('error')}
      />

      <StepNavigation>
        <StepNavigationRow>
          <span tw="ml-auto"></span>
          <StepNavigationNext
            label={translation.deliveryPage.nextStep}
            disabled={!matches('selected')}
            handleClick={events.done}
          />
        </StepNavigationRow>
      </StepNavigation>
    </Fragment>
  );
};

export default DeliveryPage;
