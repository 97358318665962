import { DoneInvokeEvent, assign, createMachine } from 'xstate';
import { enc, SHA256 } from 'crypto-js';
import { User } from '../../Models/User';
import {
  createDataEvent,
  createEvent,
  EventActionType,
} from '../../helpers/eventCreator';
import { ListCountry } from '../../Models/jena/response/GetCountriesResponse';
import { Address } from '../../Models/Address';
import { Packagetype } from '../../Models/jena/request/ListPackageTypesResponse';
import { LoadUserResponse } from '../../mappers/jenaMappers';

export interface UserMachineContext {
  user?: User;
  sessionToken?: string;
  countries: ListCountry[];
  packageTypes: Packagetype[];
}

export const UserEvents = {
  login: () => createEvent('LOGIN'),
  loggedIn: ({ user, sessionToken }: { user: User; sessionToken: string }) =>
    createDataEvent('LOGGED_IN', { user, sessionToken }),
  logout: () => createEvent('LOGOUT'),
  updateUser: (user: Partial<User>) => createDataEvent('UPDATE_USER', user),
  removeAddress: (address: Address) =>
    createDataEvent('REMOVE_ADDRESS', address),
  reloadUser: () => createEvent('RELOAD_USER'),
};

export type TUserEvents = EventActionType<typeof UserEvents>;

export const userMachine = createMachine<UserMachineContext, TUserEvents>({
  id: 'userMachine',
  initial: 'init',
  states: {
    init: {
      invoke: [
        {
          id: 'loadCountries',
          src: 'loadCountries',
          onDone: {
            target: 'checkUser',
            actions: assign({
              countries: (_, { data }) => data,
            }),
          },
          onError: 'error.loadCountry',
        },
        // {
        //   id: 'listPackageTypes',
        //   src: 'listPackageTypes',
        //   onDone: {
        //     actions: [
        //       assign({
        //         packageTypes: (_, { data }) => data,
        //       }),
        //     ],
        //   },
        // }
      ],
    },
    checkUser: {
      always: [
        {
          target: 'loadUser',
          cond: (ctx) => !!ctx.sessionToken,
        },
        {
          target: 'loggedOut',
        },
      ],
    },
    error: {
      states: {
        loadUser: {},
        loadCountry: {},
      },
    },
    loadUser: {
      invoke: {
        src: 'loadUser',
        id: 'loadUser',
        onDone: {
          target: 'loggedIn',
          actions: [
            assign({
              user: (ctx, { data }) => ({ ...ctx.user, ...data.user }),
              packageTypes: (_, { data }) => data.packageTypes,
            }),
            (ctx, event: DoneInvokeEvent<LoadUserResponse>) => {
              if (window.dataLayer) {
                window.dataLayer.push({
                  event: 'login',
                  accountType: event.data.user.hasOrg ? 'business' : 'private', // input business or private dependent on selection
                  email: event.data.user.email,
                  emailSHA256: !!event.data.user.email
                    ? SHA256(event.data.user.email).toString(enc.Hex)
                    : '', // SHA256 hashed value of the email
                  firstName: event.data.user.firstName,
                  lastName: event.data.user.lastName,
                  phoneNo1: event.data.user.phone,
                  phoneNo2: '',
                  country: !!event.data.user.countryId
                    ? ctx.countries?.find(
                        (c) => c.countryid === event.data.user.countryId
                      )?.name
                    : '',
                  countryCode: '',
                  city: event.data.user.city,
                  zipCode: event.data.user.postcode,
                  businessRegistrationNo: '', // leave without value if not applicable
                  companyName: event.data.user.orgName, // leave without value if not applicable
                  vatID: '', // leave without value if not applicable
                  internalAccountNo: event.data.user.customerNumber, // leave without value if not applicable
                });
              }
            },
          ],
        },
        onError: { target: 'error.loadUser' },
      },
    },
    loggedIn: {
      initial: 'idle',
      states: {
        idle: {
          on: {
            REMOVE_ADDRESS: 'removeAddress',
            RELOAD_USER: 'reloadUser',
          },
        },
        removeAddress: {
          invoke: {
            src: 'removeAddress',
            id: 'removeAddress',
            onDone: {
              target: 'reloadUser',
            },
            onError: 'idle',
          },
        },
        reloadUser: {
          invoke: {
            src: 'loadUser',
            id: 'loadUser',
            onDone: {
              target: 'idle',
              actions: assign({
                user: (ctx, { data }) => ({ ...ctx.user, ...data.user }),
                packageTypes: (_, { data }) => data.packageTypes,
              }),
            },
            onError: { target: '#userMachine.error.loadUser' },
          },
        },
      },
      on: {
        LOGOUT: {
          actions: [
            assign({
              user: (ctx) => ({
                id: undefined,
                firstName: '',
                lastName: '',
                hasOrg: false,
                addresses: [],
                numAddress: 0,
                username: '',
              }),
              sessionToken: (_) => undefined,
            }),
            'clearCookie',
            'reset',
          ],
          target: 'loggedOut',
        },
      },
    },
    loggedOut: {},
  },
  on: {
    LOGGED_IN: {
      actions: [
        assign({
          user: (_, { data }) => data.user,
          sessionToken: (_, { data }) => data.sessionToken,
        }),
        'setCookie',
      ],
      target: 'loadUser',
    },
    // RELOAD_USER: {
    //   target: 'loadUser',
    // },
  },
});
