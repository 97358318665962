import { assign, MachineConfig, actions } from 'xstate';
import { Address } from '../../Models/Address';
import DeliveryOption from '../../Models/DeliveryOption';
import { Parcel } from '../../Models/Parcel';
import packageReducer from './packageReducer';
import { TDeliveryEvents } from './DeliveryEvents';
import { isFuture, isValid, parseISO } from 'date-fns';
import { addMinutes, format } from 'date-fns/fp';
import { addDays } from 'date-fns/fp';
import { PartnerInformation } from '../../Models/PartnerInformation';
import { Context, TGuards } from '../../Machines/sendPackageMachine';
import { sendToast } from '../../Machines/sendToastAction';
import { ConsignmentInfo } from '../../Models/jena/response/GetConsignmentInfoResponse';
import { GenerateSupportTicketResponse } from '../../Models/jena/response/GenerateSupportTicketResponse';
import virtualPageview from '../../virtualPageview';

export type DeliveryRequestType = 'EarliestPickup' | 'LatestDelivery';
const { send, cancel } = actions;

export interface DeliveryContext {
  userLoggedIn: boolean;
  completed: boolean;
  shipper: Address;
  consignee: Address;
  addressVerified: boolean;
  packages: Parcel[];
  alternatives: DeliveryOption[];
  deliveryOption?: DeliveryOption;
  requestType: DeliveryRequestType;
  date: string;
  time: string;
  customsRequired: boolean;
  partnerInfo: PartnerInformation;
  hasNewAlternatives: boolean;
  invoiceAllowed: boolean;
  supportTicketId?: string;
  requestId?: string;
  paymentType: 'invoice' | 'card';
}

const validators = {
  date: (date?: string) => {
    const parsed = parseISO(date || '');
    return isValid(parsed) && isFuture(addDays(1)(parsed)); // TODO future is not pure!
  },
  time: (time: string = '', date: string = '') =>
    /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(time) &&
    new Date(`${date} ${time}`) > new Date(),
  requestType: (type: DeliveryRequestType) =>
    type === 'EarliestPickup' || type === 'LatestDelivery',
};

const inputStates = (invalidGuard: DeliveryGuards) => ({
  initial: 'init',
  states: {
    init: {},
    edit: {
      always: [
        {
          cond: invalidGuard,
          target: 'error',
        },
        { target: 'valid' },
      ],
    },
    valid: {},
    error: {},
  },
});

export enum DeliveryGuards {
  deliveryFormValid = 'deliveryFormValid',
  dateInvalid = 'dateInvalid',
  timeInvalid = 'timeInvalid',
  requestTypeInvalid = 'requestTypeInvalid',
  deliveryStepComplete = 'deliveryStepComplete',
  // excessiveWeight = 'excessiveWeight',
  // excessiveVolume = 'excessiveVolume',
  packagesInvalid = 'packagesInvalid',
}

const deliveryMachine: MachineConfig<
  Context & DeliveryContext,
  any,
  TDeliveryEvents
> = {
  id: 'delivery',
  initial: 'init',
  states: {
    init: {
      entry: [
        'scrollToTop',
        send('BLUR_TIME'),
        () => virtualPageview('/', 'Delivery'),
      ],
      always: [{ target: 'editing' }],
      on: {
        // Select option included in order to handle cases when you navigate back to the delivery page
        SELECT_OPTION: {
          actions: [
            assign((ctx, e) => ({
              deliveryOption: e.data,
            })),
          ],
          target: 'consignmentInfo',
        },
      },
    },
    editing: {
      id: 'editing',
      type: 'parallel',
      states: {
        // addresses: formstate,
        date: inputStates(DeliveryGuards.dateInvalid),
        time: inputStates(DeliveryGuards.timeInvalid),
        requestType: inputStates(DeliveryGuards.requestTypeInvalid),
        packages: {
          initial: 'init',
          states: {
            init: {},
            edit: {
              always: [
                {
                  cond: DeliveryGuards.packagesInvalid,
                  target: 'error.invalidPackages',
                },
                // {
                //   cond: DeliveryGuards.excessiveVolume,
                //   target: 'error.excessiveVolume',
                // },
                // {
                //   cond: DeliveryGuards.excessiveWeight,
                //   target: 'error.excessiveWeight',
                // },
                { target: 'valid' },
              ],
            },
            valid: {},
            error: {
              states: {
                // excessiveWeight: {},
                // excessiveVolume: {},
                invalidPackages: {},
              },
            },
          },
        },
      },
      always: [
        {
          cond: DeliveryGuards.deliveryFormValid,
          target: 'debouncedLoading',
          actions: assign({
            deliveryOption: (_) => undefined,
            hasNewAlternatives: (_) => false,

            alternatives: (_) => [],
          }),
        },
        // {
        //   target: '#delivery',
        //   actions: assign({
        //     alternatives: (_) => [],
        //     deliveryOption: (_) => undefined,
        //   }),
        // },
      ],
    },
    debouncedLoading: {
      entry: [
        cancel('debounced-loading-timer'),
        send(
          { type: 'LOAD_ALTERNATIVES' },
          { delay: 1500, id: 'debounced-loading-timer' }
        ),
      ],
      on: {
        LOAD_ALTERNATIVES: { target: 'loading' },
      },
    },
    error: {},
    loading: {
      entry: [
        sendToast(
          { type: 'info', id: 'load-alternatives' },
          'deliveryPage',
          'loadingAlternatives'
        ),
      ],
      invoke: {
        id: 'loadAlternatives',
        src: 'loadAlternatives',
        onError: {
          target: 'error',
          // actions: assign( (ctx,e) => ({errorMessage: e.error}))
          actions: sendToast(
            { type: 'error', id: 'load-alternatives' },
            'deliveryPage',
            'errorLoadingAlternatives'
          ),
        },
        onDone: {
          target: 'picking',
          actions: [
            assign((ctx, e) => ({
              alternatives: e.data.Alternatives,
              requestId: e.data.RequestId,
              customsRequired: (e.data.Customs + '').toLowerCase() === 'true',
              hasNewAlternatives: true,
              supportTicketId: undefined,
              deliveryOption: undefined,
            })),
          ],
        },
      },
    },
    supportTicket: {
      initial: 'creating',
      states: {
        // init: {
        // },
        creating: {
          invoke: {
            src: 'generateSupportTicket',
            id: 'generateSupportTicket',
            onError: {
              target: 'error',
            },
            onDone: {
              target: 'created',
              actions: [
                assign((ctx, e) => ({
                  supportTicketId: (e.data as GenerateSupportTicketResponse)
                    .supportticketid,
                })),
              ],
            },
          },
        },
        created: {
          always: {
            target: '#delivery.picking',
          },
        },
        error: {},
      },
    },
    picking: {
      initial: 'unknown',
      id: 'picking',
      states: {
        unknown: {
          always: [
            {
              target: 'idle',
              cond: (ctx) => ctx.alternatives && ctx.alternatives.length > 0,
              actions: sendToast(
                { type: 'success', id: 'load-alternatives' },
                'deliveryPage',
                'newAlternativesLoaded'
              ),
            },
            {
              target: 'nodata',
              actions: sendToast(
                { type: 'info', id: 'load-alternatives' },
                'deliveryPage',
                'noAlternativesFound'
              ),
            },
          ],
        },
        nodata: {},
        idle: {
          on: {
            SELECT_OPTION: {
              actions: [
                assign((ctx, e) => ({
                  deliveryOption: e.data,
                  hasNewAlternatives: false,
                })),
                // sendToast(
                //   { type: 'success' },
                //   'deliveryPage',
                //   'alternativeSelected'
                // ),
              ],
              target: '#delivery.consignmentInfo',
            },
          },
        },
      },
    },

    consignmentInfo: {
      id: 'consignmentInfo',
      invoke: {
        src: 'getConsignmentInfo',
        id: 'getConsignmentInfo',
        onError: {
          target: 'error',
          // actions: assign( (ctx,e) => ({errorMessage: e.error}))
          actions: sendToast(
            { type: 'error', id: 'load-alternatives' },
            'deliveryPage',
            'errorLoadingAlternatives'
          ),
        },
        onDone: {
          target: 'selected',
          actions: [
            assign((ctx, e) => {
              const { cardcheckurl, partnercompid, reporturl, invoiceallowed } =
                e.data as ConsignmentInfo;
              return {
                partnerInfo: {
                  ...ctx.partnerInfo,
                  cardCheckUrl: cardcheckurl,
                  partnerCompId: partnercompid,
                  reportUrl: reporturl,
                },
                invoiceAllowed: invoiceallowed === '1' ? true : false,
                paymentType: invoiceallowed === '1' ? 'invoice' : 'card',
              };
            }),
            sendToast(
              { type: 'success' },
              'deliveryPage',
              'alternativeSelected'
            ),
            (ctx, e) => {
              if (window.dataLayer) {
                window.dataLayer.push({ bookingAdded: null });
                window.dataLayer.push({
                  event: 'deliveryAlternativeSelected',
                  bookingAdded: {
                    revenue: ctx.deliveryOption?.OriginalPriceExcludingVAT, // decimal separate is a full stop and must always be followed by 2 decimals.
                    currency: ctx.deliveryOption?.Currency,
                    vat: ctx.deliveryOption?.Vat, // decimal separate is a full stop and must always be followed by 2 decimals.
                    shipperCountry: ctx.shipper.country,
                    shipperCountryCode: ctx.shipper.countryCode,
                    recipientCountry: ctx.consignee.country,
                    recipientCountryCode: ctx.consignee.countryCode,
                    awbNo: '',
                    deliveryQuantity: ctx.packages.reduce(
                      (prev, curr, i, p) => prev + curr.quantity,
                      0
                    ), // if e.g. “one package” of 10 envelops, then this value is 10
                    bookingQuantity: ctx.packages.length, // if two package options are chosen and each of them has 5 in quantity, then this value is still 2
                    paymentMethod: e.data.invoiceallowed ? 'invoice' : 'card',
                    deliveryDetails: ctx.packages.map((x) => ({
                      // identical object per package line added and booked
                      packageType: x.parcelType?.name, // e.g. envelope if chosen from dropdown
                      packageQuantity: x.quantity, // e.g. envelope if chosen from dropdown
                      'packageWeight(kg)': x.weight, // e.g. envelope if chosen from dropdown
                      'packageHeight(cm)': x.height, // e.g. envelope if chosen from dropdown
                      'packageWidth(cm)': x.width, // e.g. envelope if chosen from dropdown
                      'packageLength(cm)': x.length, // e.g. envelope if chosen from dropdown
                    })),
                  },
                });
              }
            },
          ],
        },
      },
    },
    selected: {
      id: 'selected',
      on: {
        DELIVERY_DONE: [
          {
            cond: DeliveryGuards.deliveryStepComplete,
            target: '#packageInformation',
          },
          {
            target: [
              '#delivery.editing.date',
              '#delivery.editing.time',
              '#delivery.editing.requestType',
              '#delivery.editing.packages',
            ],
          },
        ],
        SELECT_OPTION: {
          actions: [
            assign((ctx, e) => ({
              deliveryOption: e.data,
              hasNewAlternatives: false,
            })),
          ],
          target: '#delivery.consignmentInfo',
        },
      },
    },
  },
  on: {
    ADDRESS_VERIFIED: {
      target: '#delivery.editing',
      actions: [
        assign({
          shipper: (ctx, { data }) => {
            if (
              ctx.shipper &&
              data.shipper &&
              ctx.shipper.street === data.shipper.street &&
              ctx.shipper.streetNumber === data.shipper.streetNumber &&
              ctx.shipper.postCode === data.shipper.postCode
            ) {
              const { firstName, lastName, phone, name, addressName, id } =
                ctx.shipper;
              return {
                firstName,
                lastName,
                phone,
                name,
                id,
                addressName,
                ...data.shipper,
              };
            }
            return {
              ...data.shipper,
            };
          },
          consignee: (ctx, { data }) => {
            if (
              ctx.consignee &&
              data.consignee &&
              ctx.consignee.street === data.consignee.street &&
              ctx.consignee.streetNumber === data.consignee.streetNumber &&
              ctx.consignee.postCode === data.consignee.postCode
            ) {
              const { firstName, lastName, phone, name, addressName, id } =
                ctx.consignee;
              return {
                firstName,
                lastName,
                phone,
                name,
                addressName,
                id,
                ...data.consignee,
              };
            }
            return {
              ...data.consignee,
            };
          },
          addressVerified: (ctx, { data }) => data.verified,
        }),
        'addressVerified',
      ],
    },
    SET_PARTNERINFO: {
      actions: assign({
        partnerInfo: (_, { data }) => data,
      }),
    },
    SELECT_DATE: [
      {
        actions: assign({
          date: (_, event) => event.data,
        }),
        target: '#delivery.editing.time.edit',
      },
    ],
    SELECT_TIME: [
      {
        target: '#delivery.editing.time.edit',
        actions: assign({
          time: (_, event) => event.data,
        }),
      },
    ],
    BLUR_TIME: [
      {
        cond: (ctx) =>
          new Date(`${ctx.date} ${ctx.time}`) < addMinutes(10)(new Date()),
        target: '#delivery.editing.time.edit',
        actions: assign({
          time: (ctx, event) => format('HH:mm')(addMinutes(15)(new Date())),
        }),
      },
    ],
    SELECT_REQUESTTYPE: [
      {
        target: '#delivery.editing.requestType.edit',
        actions: assign({
          requestType: (_, event) => event.data,
        }),
      },
    ],
    CHANGE_PACKAGES: {
      target: '#delivery.editing.packages.edit',
      actions: assign((ctx, e) => ({
        packages: packageReducer(ctx.packages, e.data, ctx.packageTypes),
      })),
    },
    SET_LOGGED_IN: {
      target: '#delivery',
      actions: assign({
        packages: (_) => [],
      }),
    },
    CREATE_SUPPORT_TICKET: { target: '#delivery.supportTicket' },
  },
};

export const packageValid = (parcel: Parcel) =>
  !!parcel.parcelType &&
  !!parcel.weight &&
  parcel.weight > 0 &&
  parcel.weight <= parcel.parcelType.maxweight &&
  !!parcel.width &&
  parcel.width > 0 &&
  parcel.width <= parcel.parcelType.maxwidth &&
  !!parcel.height &&
  parcel.height > 0 &&
  parcel.height <= parcel.parcelType.maxheight &&
  !!parcel.length &&
  parcel.length > 0 &&
  parcel.length <= parcel.parcelType.maxlength &&
  parcel.quantity > 0;

export const deliveryMachineGuards: TGuards<DeliveryGuards> = {
  packagesInvalid: (ctx) =>
    !ctx.packages.reduce((valid: boolean, p) => valid && packageValid(p), true),
  // excessiveVolume: (ctx) => {
  //   let volume = ctx.packages.reduce(
  //     (vol, p) => vol + p.quantity * p.height * p.length * p.width,
  //     0
  //   );
  //   return volume / 5000 > 50;
  // },
  // excessiveWeight: (ctx) => {
  //   let weight = ctx.packages.reduce((w, p) => w + p.weight * p.quantity, 0);
  //   return weight >= 50;
  // },

  deliveryFormValid: (ctx, e, m) =>
    ctx.packages.length > 0 &&
    ctx.packages.length <= 100 &&
    validators.requestType(ctx.requestType) &&
    validators.date(ctx.date) &&
    validators.time(ctx.time, ctx.date) &&
    !deliveryMachineGuards.packagesInvalid(ctx, e, m) &&
    // !deliveryMachineGuards.excessiveVolume(ctx, e, m) &&
    // !deliveryMachineGuards.excessiveWeight(ctx, e, m) &&
    ctx.addressVerified,

  dateInvalid: (ctx) => !validators.date(ctx.date),

  timeInvalid: (ctx) => !validators.time(ctx.time, ctx.date),

  requestTypeInvalid: (ctx) => !validators.requestType(ctx.requestType),

  deliveryStepComplete: (ctx) =>
    ctx.addressVerified && !!ctx.deliveryOption?.AlternativeId?.length,
};

export default deliveryMachine;
