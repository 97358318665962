/** @jsxImportSource @emotion/react */
import tw, { css, styled } from 'twin.macro';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { ReactNode } from 'react';

type Variant = 'processing-order' | 'loading-delivery-alternatives';

const Wrapper = styled.div(({ variant }: { variant?: Variant }) => [
  tw`flex items-center justify-center flex-col inset-0 w-full h-full`,
  variant === 'processing-order'
    ? tw`
        fixed z-50 bg-white bg-opacity-75
      `
    : null,
  variant === 'loading-delivery-alternatives'
    ? [
        tw`absolute bg-white bg-opacity-75`,
        css`
          z-index: 1;
        `,
      ]
    : null,
]);

export const LoaderLabel = ({ label }: { label: ReactNode }) => (
  <span css={[tw`inline-block px-8 mt-8 text-center`]}>{label}</span>
);

interface LoaderProps {
  variant?: Variant;
  label?: string;
  children?: ReactNode;
}

const Loader = ({ variant, children }: LoaderProps) => {
  return (
    <Wrapper variant={variant}>
      <PropagateLoader size={16} color={'rgba(215, 52, 44, 0.5)'} />
      {children}
    </Wrapper>
  );
};

export default Loader;
