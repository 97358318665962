// import { ParcelTypes } from './../Models/Parcel';
import { Currency } from './../enums/Currency';

export enum LanguageCode {
  'da-DK' = 'Dansk',
  'en-GB' = 'English',
  'nb-NO' = 'Norsk',
  'fi-FI' = 'Suomeksi',
  'sv-SE' = 'Svenska',
}
export enum LanguageFlag {
  'sv-SE' = 'se',
  'en-GB' = 'gb',
  'da-DK' = 'da',
  'nb-NO' = 'no',
  'fi-FI' = 'fi',
}
export type TLanguageCode = keyof typeof LanguageCode;

interface Policy {
  label: string;
  urlLabel: string;
  url: string;
}

type TermsAndPolicies =
  | 'privacyPolicy'
  | 'dangerousGoods'
  | 'termsAndConditions';

export interface Translations {
  meta: {
    appName: string;
    language: string;
    languageCode: keyof typeof LanguageCode;
    currency: keyof typeof Currency;
    email: string;
    phone: {
      label: string;
      number: string;
    };
  };
  packageTypes: {
    error: string;
  };
  deliveryPage: {
    title: string;
    tab: string;
    nextStep: string;
    addressTitle: string;
    preamble: string;
    fillInTheFieldsAboveToSeeDeliveryAlternatives: string;
    newAlternativesLoaded: string;
    loadingAlternatives: string;
    errorLoadingAlternatives: string;
    alternativeSelected: string;
    noAlternativesFound: string;
    invalidPackages: string;
    excessiveWeight: string;
    excessiveVolume: string;
    selectDeliveryOptions: string;
  };
  packageInformationPage: {
    title: string;
    tab: string;
    previousStep: string;
    nextStep: string;
    preamble: string;
    titleContentExample: string;
    packageContentLabel: string;
    placeHolderExample: string;
    seeExamples: string;
    writeLike: string;
    dontWrite: string;
    howToWrite: { correct: string; incorrect: string }[];
  };
  senderAndConsigneePage: {
    title: string;
    tab: string;
    previousStep: string;
    nextStep: string;
    nextStepCustoms: string;
    preamble: string;
  };
  customsPage: {
    title: string;
    tab: string;
    previousStep: string;
    nextStep: string;
    preamble: string;
    eori: {
      linkText: string;
      linkUrl: string;
      title: string;
      description: string;
    };
    customsValue: string;
    customsValueDescription: string;
    customsValueLinkText: string;
    customsValueLinkUrl: string;
    customsInvoiceNumber: string;
    customsInvoiceNumberDescription: string;
    customsInvoiceNumberLinkDescription: string;
    customsInvoiceNumberLinkText1: string;
    customsInvoiceNumberLinkUrl1: string;
    customsInvoiceNumberLinkText2: string;
    customsInvoiceNumberLinkUrl2: string;
    customsNumber: string;
    customsNumberDescription1: string;
    customsNumberDescription2: string;
    customsContent: string;
    shipperEori: string;
    consigneeEori: string;
    commodityCodeLength: string;
  };
  paymentPage: {
    title: string;
    tab: string;
    preamble: string;
    previousStep: string;
    previousStepCustoms: string;
    nextStepByCard: string;
    nextStepByInvoice: string;
    paymentOptions: string;
    paymentReference: string;
    paymentReferencePlaceholder: string;
    signIntoPayByInvoice: string;
    invoiceNotAllowed: string;
    campaignCode: string;
    invalidCampaignCode: string;
    campaignCodeApplied: string;
    apply: string;
  };
  loginPage: {
    title: string;
  };
  registerPage: {
    title: string;
    customerTypeHeader: string;
    existingCustomerHeader: string;
    loginText: string;
    buttonText: string;
    continueButtonText: string;
    previousButtonText: string;
    fields: {
      customerTypeCompany: string;
      customerTypePrivate: string;
      existingYes: string;
      existingNo: string;
      orgName: string;
      orgNumber: string;
      vatNumber: string;
      customerNumber: string;
      firstName: string;
      lastName: string;
      email: string;
      cellPhone: string;
      phone: string;
      street: string;
      streetNumber: string;
      city: string;
      postalCode: string;
      country: string;
    };
    invalidNbr: string;
    nonExistantNbr: string;
  };
  orderConfirmationPage: {
    title: string;
    preamble: string;
    yourBookingNumber: string;
  };
  orderSummary: {
    title: string;
    preamble: string;
  };
  advise: {
    title: string;
    description: string;
    type: string;
    method: string;
    bookingConfirmation: string;
    deliveryConfirmation: string;
    email: string;
    textMessage: string;
    addMoreNotifications: string;
  };
  requiredField: string;
  timeHasPassed: string;
  invalidEmailAddress: string;
  shipper: string;
  consignee: string;
  shipperAddress: string;
  consigneeAddress: string;
  shipperAddressPlaceholder: string;
  consigneeAddressPlaceholder: string;
  pickUpAndDelivery: string;
  pickUp: string;
  delivery: string;
  earliestPickup: string;
  latestDelivery: string;
  shippingDetails: string;
  select: string;
  selected: string;
  selectDeliveryOptions: string;
  price: string;
  date: string;
  time: string;
  package: string;
  add: string;
  addMore: string;
  remove: string;
  addPackage: string;
  removePackage: string;
  yourPackages: string;
  addMorePackages: string;
  whatIsTheSizeOfYourPackage: string;
  weight: string;
  width: string;
  height: string;
  length: string;
  quantity: string;
  quantityPcs: string;
  pcs: string;
  added: string;
  vat: string;
  withoutVat: string;
  withVat: string;
  discount: string;
  total: string;
  clear: string;
  inKilogram: string;
  inCm: string;
  today: string;
  tomorrow: string;
  currency: string;
  countryOfOrigin: string;
  username: string;
  password: string;
  currentPassword: string;
  newPassword: string;
  newPassword2: string;
  passwordResetLinkCreated: string;
  signIn: string;
  signOut: string;
  signedIn: string;
  signedOut: string;
  signInErrorMessage: string;
  forgotPassword: string;
  resetPassword: string;
  changePassword: string;
  passwordReset: string;
  passwordChanged: string;
  register: string;
  loading: string;
  company: string;
  save: string;
  name: string;
  phone: string;
  messageToDriver: string;
  messageToDriverExample: string;
  paymentProcessing: string;
  invoice: string;
  creditcard: string;
  creditCardHoldersName: string;
  email: string;
  country: string;
  postalAddress: string;
  postalCode: string;
  postalArea: string;
  open: string;
  close: string;
  or: string;
  welcome: string;
  youAreloggedInAs: string;
  profile: string;
  help: string;
  helpPage: {
    getSupportTicket: string;
    content1: string;
    content2: string;
    yourTicketNumber: string;
    supportNumber: string;
  };
  orderFailed: {
    cancelled: string;
    error: string;
    declined: string;
    pickupMissed: string;
  };
  support: {
    needHelp: string;
    contactOurSupport: string;
  };
  carbonDioxideMessage: string;
  createNewBooking: string;
  addressBook: string;
  addressBookModal: {
    title: string;
    pickAddress: string;
    noSavedAddresses: string;
    name: string;
    street: string;
    streetNumber: string;
    city: string;
    postalCode: string;
    country: string;
    newAddress: string;
    addressName: string;
    addAnother: string;
    addressSaved: string;
    editAddress: string;
    showing: (showing: number, total: number) => string;
  };
  addressValidationErrors: {
    missingStreet: string;
    missingStreetNumber: string;
    verificationError: string;
    error: string;
  };
  // parcelTypes: {
  //   [ParcelTypes.envelope]: string;
  //   [ParcelTypes.bag]: string;
  //   [ParcelTypes.smallBox]: string;
  //   [ParcelTypes.largeBox]: string;
  //   [ParcelTypes.pallet]: string;
  //   [ParcelTypes.other]: string;
  // };
  unitTypes: {
    kg: string;
    cm: string;
  };
  termsAndPolicies: Record<TermsAndPolicies, Policy>;
  days: {
    monday: string;
    tuesday: string;
    wednesday: string;
    thursday: string;
    friday: string;
    saturday: string;
    sunday: string;
  };
  insurance: {
    title: string;
    description: string;
    labelToggle: string;
    labelInput: string;
    fee: string;
    insurancePremium: (
      rate: number,
      minPrice: number,
      currency: string
    ) => string;
  };
}
