import { Translations } from './TranslationType';
import { Currency } from './../enums/Currency';

const da: Translations = {
  meta: {
    appName: 'Send pakke',
    language: 'Dansk',
    languageCode: 'da-DK',
    currency: Currency.DKK,
    email: 'kundeservice@jetpak.dk',
    phone: {
      label: '+45 36 32 11 00',
      number: '+45 36 32 11 00',
    },
  },
  packageTypes: { error: 'Error loading package types' },
  deliveryPage: {
    title: 'Alternativer for levering',
    tab: 'Alternativer for levering',
    nextStep: 'Fortsæt til pakkeindhold',
    addressTitle: 'Indtast oplysninger',
    preamble: 'Skriv hvad din forsendelse indeholder',
    fillInTheFieldsAboveToSeeDeliveryAlternatives:
      'Udfyld felterne ovenfor for at se leveringsmuligheder.',
    newAlternativesLoaded: 'Nye alternativer tilgængelige',
    loadingAlternatives: 'Indlæser leverings alternativer',
    errorLoadingAlternatives: 'Fejl ved indlæsning af alternativer',
    alternativeSelected: 'Levering er valgt',
    noAlternativesFound:
      'Ingen leveringsalternativer fundet, opdater søgekriterier og prøv igen.',
    invalidPackages: 'Ugyldigt input til pakkestørrelse/vægt',
    excessiveVolume:
      'Levering for stor til online booking. Kontakt venligst kundesupport',
    excessiveWeight:
      'Levering for tung til online booking. Kontakt venligst kundesupport',
    selectDeliveryOptions: 'Vælg leveringsmetode',
  },
  packageInformationPage: {
    title: 'Pakkens indhold',
    tab: 'Pakkens indhold',
    previousStep: 'Gå tilbage',
    nextStep: 'Fortsæt til afsender & modtager',
    preamble: 'Skriv hvad din forsendelse indeholder',
    titleContentExample: 'Beskriv dit indhold på denne måde',
    packageContentLabel: 'Beskriv indholdet',
    placeHolderExample: 'Hovedtelefoner, mobiltelefon og bøger ...',
    seeExamples: 'Se eksempler',
    writeLike: 'Dette er ok',
    dontWrite: 'Skriv ikke',
    howToWrite: [
      {
        correct: 'Hovedtelefoner, mobiltelefon',
        incorrect: 'Elektronik',
      },
      {
        correct: 'Sportstaske',
        incorrect: 'Varer',
      },
      {
        correct: 'Pas',
        incorrect: 'Materiale',
      },
      {
        correct: 'Computer',
        incorrect: 'Dele',
      },
      {
        correct: 'Sportstaske',
        incorrect: '',
      },
    ],
  },
  senderAndConsigneePage: {
    title: 'Afsender & Modtager',
    tab: 'Afsender & Modtager',
    previousStep: 'Gå tilbage',
    nextStep: 'Fortsæt til oversigt',
    nextStepCustoms: 'Fortsæt til toldoplysninger',
    preamble: 'Indtast oplysninger for afsender og modtager',
  },
  customsPage: {
    title: 'Toldoplysninger',
    tab: 'Told',
    previousStep: 'Gå tilbage',
    nextStep: 'Fortsæt til oversigt',
    preamble:
      'Din virksomhed skal have et EORI-nummer for at kunne importere og eksportere varer (EORI = Economic Operator Registration and Identification).',
    eori: {
      linkText: 'Læs mere om EORI numre',
      linkUrl: 'https://www.legaldesk.dk/artikler/eori-nummer',
      description: '',
      title: 'EORI',
    },
    customsValue: 'Toldværdi',
    customsValueDescription:
      'Toldværdien er baseret på leverandørernes pris på varerne. Oplysninger om andre omkostninger (undtagen omkostningerne til varerne), der er inkluderet i toldværdien, kan findes ',
    customsValueLinkText:
      'https://toldst.dk/borger/internethandel/toldberegneren',
    customsValueLinkUrl:
      'https://toldst.dk/borger/internethandel/toldberegneren',
    customsInvoiceNumber: 'Toldfaktura Nr.',
    customsInvoiceNumberDescription:
      'En faktura skal ledsage forsendelsen. Hvis indholdet skal sælges i modtagerlandet, skal du bruge en kommerciel faktura. Hvis produktet ikke skal sælges, så brug proforma faktura.',
    customsInvoiceNumberLinkDescription: 'Downloader:',
    customsInvoiceNumberLinkText1: 'Handelsfaktura',
    customsInvoiceNumberLinkUrl1:
      'https://jetpak.com/files/5714/8613/0709/Jetpak_Commercial_Invoice.pdf',
    customsInvoiceNumberLinkText2: 'Proforma faktura',
    customsInvoiceNumberLinkUrl2:
      'https://jetpak.com/files/8214/8613/0638/Jetpak_Proforma_Invoice.pdf',
    customsNumber: 'HS kode / varekode',
    customsNumberDescription1:
      'Varekode afgør, hvilken toldkurs der anvendes for varen, og består af et 10 cifret nummer. Du kan søge efter varekoden på toldmyndighedernes hjemmeside',
    customsNumberDescription2:
      'På toldfakturaen skrives i varekoden i det tilsvarende felt.',
    customsContent: '',
    shipperEori: 'Eori shipper',
    consigneeEori: 'Eori consignee',
    commodityCodeLength: 'Minimum 6 characters',
  },
  paymentPage: {
    title: 'Oversigt',
    tab: 'Oversigt',
    preamble: '',
    previousStep: 'Gå tilbage',
    previousStepCustoms: 'Gå tilbage til told',
    nextStepByCard: 'Fortsæt til betaling',
    nextStepByInvoice: 'Betal med faktura',
    paymentOptions: 'Betalings muligheder',
    paymentReference: 'Betalings reference',
    paymentReferencePlaceholder:
      'Til din reference, dvs. et indkøbsordrenummer',
    signIntoPayByInvoice: 'Du skal logge ind for at kunne betale med faktura.',
    invoiceNotAllowed:
      'Betaling via faktura er i øjeblikket ikke tilladt. Kontakt venligst support for mere information.',
    campaignCode: 'Kampagnekode',
    campaignCodeApplied: 'Kampagnekode tilføjet',
    invalidCampaignCode: 'Ugyldig kampagnekode',
    apply: 'Anvend kampagnekode',
  },
  loginPage: {
    title: 'Log på',
  },
  registerPage: {
    title: 'Opret en konto',
    buttonText: 'Opret Konto',
    continueButtonText: 'Fortsæt',
    previousButtonText: 'Tilbage',
    customerTypeHeader: 'Jeg vil gerne',
    existingCustomerHeader:
      'Findes der allerede en konto hos os i din organisation?',
    loginText: 'Har du en konto?',
    fields: {
      existingNo: 'Ny kunde',
      existingYes: 'Der findes allerede en konto hos Jetpak',
      customerTypeCompany: 'Virksomhedskonto',
      customerTypePrivate: 'Privat konto',
      orgName: 'Virksomhedens Navn',
      orgNumber: 'Virksomhedens CVR nummer',
      vatNumber: 'CVR Nummer',
      customerNumber: 'Kundenummer',
      firstName: 'Fornavn',
      lastName: 'Efternavn',
      email: 'E-mail',
      cellPhone: 'Mobiltelefon',
      phone: 'Telefon',
      street: 'Vej',
      streetNumber: 'Gade Nummer',
      city: 'By',
      postalCode: 'Postnummer',
      country: 'Land',
    },
    invalidNbr: 'Ikke et gyldigt kontonummer',
    nonExistantNbr: 'Kontonummer ikke fundet',
  },
  orderConfirmationPage: {
    title: 'Bekræftelse af booking',
    preamble: '',
    yourBookingNumber: 'Dit bookingnummer (AWB)',
  },
  orderSummary: {
    title: 'Opsummering',
    preamble: 'Få det samlede overblik på din booking',
  },
  advise: {
    title: 'Booking og levering bekræftelse',
    description:
      'Vælg om du vil have din reservationsbekræftelse og levering information via sms og/eller e-mail',
    type: 'Indtast',
    method: 'Metode',
    bookingConfirmation: 'Bekræftelse af booking',
    deliveryConfirmation: 'Bekræftelse af levering',
    email: 'E-mail',
    textMessage: 'SMS',
    addMoreNotifications: 'Tilføj flere notifikationer',
  },
  requiredField: 'Dette felt er påkrævet',
  timeHasPassed: 'Tiden er gået',
  invalidEmailAddress: 'E-mail-adressen skal matche formatet email@example.com',
  shipper: 'Afsender',
  consignee: 'Modtager',
  shipperAddress: 'Adresse afsender',
  consigneeAddress: 'Adresse modtager',
  shipperAddressPlaceholder: 'Søg afsender adresse',
  consigneeAddressPlaceholder: 'Søg modtager adresse',
  pickUpAndDelivery: 'Afhentning/Levering',
  pickUp: 'Afhentnings tid',
  delivery: 'Leverings tid',
  earliestPickup: 'Tidligste afhentning',
  latestDelivery: 'Seneste levering',
  shippingDetails: 'Leveringsoplysninger',
  select: 'Vælg',
  selected: 'Vælg',
  selectDeliveryOptions: 'Vælg leverings muligheder',
  price: 'Pris',
  date: 'Dato',
  time: 'Tid',
  package: 'Pakke',
  add: 'Tilføj',
  addMore: 'Tilføj mere',
  remove: 'Fjern',
  addPackage: 'Tilføj pakke',
  removePackage: 'Fjern pakke',
  yourPackages: 'Dine pakker',
  addMorePackages: 'Tilføj flere pakker',
  whatIsTheSizeOfYourPackage: 'Hvor stor er din pakke?',
  weight: 'Vægt',
  width: 'Bredde',
  height: 'Højde',
  length: 'Længde',
  quantity: 'Antal',
  quantityPcs: 'Antal',
  pcs: 'stk',
  added: 'Tilføjet',
  vat: 'moms',
  withoutVat: 'excl. moms',
  withVat: 'incl. moms',
  total: 'Total',
  clear: 'Slet',
  inKilogram: 'i kg',
  inCm: 'i cm',
  discount: 'Rabat',
  today: 'Idag',
  tomorrow: 'I morgen',
  currency: 'Valuta',
  countryOfOrigin: 'Oprindelsesland',
  username: 'Brugernavn',
  password: 'Password',
  currentPassword: 'Nuværende adgangskode',
  newPassword: 'Ny adgangskode',
  newPassword2: 'Gentag adgangskode',
  passwordResetLinkCreated:
    'Et link til nulstilling af adgangskode er blevet e-mailet til dig',
  signIn: 'Log på',
  signOut: 'Log af',
  signedIn: 'Logget på',
  signedOut: 'Logget af',
  signInErrorMessage: `Din Jetpak konto blev ikke fundet`,
  forgotPassword: 'Glemt password?',
  resetPassword: 'Nulstil adgangskode',
  passwordChanged: 'Din adgangskode er blevet ændret',
  changePassword: 'Skift adgangskode',
  profile: 'Profil',
  passwordReset: 'Din adgangskode er nulstillet',
  register: 'Opret en konto',
  loading: 'Indlæser',
  company: 'Virksomhed',
  name: 'Navn',
  phone: 'Telefon',
  messageToDriver: 'Besked til chauffør',
  messageToDriverExample: 'Kode til port, anden information ...',
  paymentProcessing: 'Vent venligst mens vi behandler din forespørgsel',
  invoice: 'Faktura',
  creditcard: 'Kreditkort',
  creditCardHoldersName: 'Kreditkortholders navn',
  email: 'E-mail',
  country: 'Land',
  postalAddress: 'Postadresse',
  postalCode: 'Postnummer',
  postalArea: 'By',
  open: 'Åbn',
  close: 'Luk',
  or: 'eller',
  help: 'Hjælp',
  helpPage: {
    getSupportTicket: 'Opret support forespørgsel',
    content1:
      'Hvis du har problemer med din booking, skal du oprette en support forespørgsel',
    content2:
      'Ring til vores servicecenter og angiv dette forespørgselsnummer    .',
    supportNumber: 'Forespørgselsnummer',
    yourTicketNumber: 'Dit forespørgselsnummer er',
  },
  welcome: 'Velkommen',
  youAreloggedInAs: 'Du er logget på som',
  carbonDioxideMessage: 'Foreløbige CO2-emissioner',
  createNewBooking: 'Opret ny booking',
  addressBook: 'Adressebog',
  addressBookModal: {
    title: 'Adressebog',
    pickAddress: 'Vælg en adresse nedenfor:',
    noSavedAddresses: 'Der findes ingen gemte adresser.',
    name: 'Navn',
    street: 'Vej',
    streetNumber: 'Gade Nummer',
    city: 'By',
    postalCode: 'Postnummer',
    country: 'Land',
    addAnother: 'Add another',
    addressName: 'Navn',
    addressSaved: 'Gemt',
    newAddress: 'Ny adresse',
    editAddress: 'Rediger adresse',
    showing: (showing, total) => `Viser ${showing} af ${total} gemte adresser`,
  },
  addressValidationErrors: {
    missingStreet: 'Manglende gadenavn',
    missingStreetNumber: 'Manglende gadenummer',
    verificationError: 'Adressen kunne ikke verificeres',
    error: 'Adressen kunne ikke verificeres',
  },
  orderFailed: {
    cancelled: 'Betaling annulleret',
    error: 'Fejl i oprettelse af ordre',
    declined: 'Dit kort blev afvist',
    pickupMissed:
      'Afhentningstiden er ikke tilgængelig. Søg efter nye transportalternativer      .',
  },
  support: {
    needHelp: 'Har du brug for hjælp?',
    contactOurSupport: 'Kontakt vores support.',
  },
  // parcelTypes: {
  //   envelope: 'Konvolut',
  //   bag: 'Pose',
  //   smallBox: 'Lille kasse',
  //   largeBox: 'Stor kasse',
  //   pallet: 'Palle',
  //   other: 'Andet',
  // },
  unitTypes: {
    kg: 'kg',
    cm: 'cm',
  },
  termsAndPolicies: {
    dangerousGoods: {
      label: 'Jeg har læst og forstået begrænsningerne for',
      urlLabel: 'transport af farligt gods',
      url: 'https://jetpak.com/dk/vilkar/vigtig-information/farligt-gods/',
    },
    privacyPolicy: {
      label: `Jeg har læst og forstået Jetpak's
`,
      urlLabel: 'Erklæring Om Privatliv',
      url: 'https://jetpak.com/dk/vilkar/privacy-statement/',
    },
    termsAndConditions: {
      label: `Jeg accepterer Jetpak's
`,
      urlLabel: 'Vilkår & Betingelser',
      url: 'https://jetpak.com/dk/vilkar/generelle-transportvilkar/',
    },
  },
  days: {
    monday: 'Mandag',
    tuesday: 'Tirsdag',
    wednesday: 'Onsdag',
    thursday: 'Torsdag',
    friday: 'Fredag',
    saturday: 'Lørdag',
    sunday: 'Søndag',
  },
  insurance: {
    title: 'Forsikring',
    description:
      'For at forsikre dine varer, bedes du angive værdien, således prisen kan beregnes. Prisen vil blive tilføjet til din ordre.',
    labelToggle: 'Tilføj forsikring',
    labelInput: 'Forsikringsværdi',
    fee: 'Forsikringsgebyr',
    insurancePremium: (rate, minPrice, currency) =>
      `Forsikrings præmien er ${rate}% af vareværdien, men mindst ${minPrice} ${currency}. Selvrisiko er 0 kroner.`,
  },
  save: 'Save',
};
export default da;
