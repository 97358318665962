import { Translations } from './TranslationType';
import { Currency } from './../enums/Currency';

const sv: Translations = {
  meta: {
    appName: 'Skicka paket',
    language: 'Svenska',
    languageCode: 'sv-SE',
    currency: Currency.SEK,
    email: 'kundservice@jetpak.se',
    phone: {
      label: '+46 77-570 00 00',
      number: '+46775700000',
    },
  },
  packageTypes: { error: 'Error loading package types' },
  deliveryPage: {
    title: 'Transportalternativ',
    tab: 'Transportalternativ',
    nextStep: 'Fortsätt till paketinnehåll',
    preamble: '',
    addressTitle: 'Vart vill du skicka?',
    newAlternativesLoaded: 'Nya alternativ laddade nedan',
    fillInTheFieldsAboveToSeeDeliveryAlternatives:
      'Fyll i fälten ovanför för att se transportalternativ.',
    loadingAlternatives: 'Hämtar transportalternativ',
    errorLoadingAlternatives: 'Fel vid hämtning av transportalternativ',
    alternativeSelected: 'Leverans vald',
    noAlternativesFound:
      'Inga transportalternativ hittades, uppdatera sökkriterierna och försök igen.',
    invalidPackages: 'Felaktigt värde för paketen, kontrollera fälten i rött',
    excessiveVolume:
      'Paketen för stora för online-bokning. Kontakta kundtjänst så hjälper vi dig.',
    excessiveWeight:
      'Paketen för tunga för online-bokning. Kontakta kundtjänst så hjälper vi dig.',
    selectDeliveryOptions: 'Välj transportalternativ nedan',
  },
  packageInformationPage: {
    title: 'Paketinnehåll',
    tab: 'Paketinnehåll',
    previousStep: 'Tillbaka',
    nextStep: 'Fortsätt till avsändare & mottagare',
    preamble: 'Ange försändelsens innehåll',
    titleContentExample: 'Exempel på innehåll',
    packageContentLabel: 'Beskriv paketinnehållet',
    placeHolderExample: 'T.ex. hörlurar, mobiltelefoner och böcker...',
    seeExamples: 'Se exempel',
    writeLike: 'Skriv så',
    dontWrite: 'Skriv inte',
    howToWrite: [
      {
        correct: 'Hörlurar, mobiltelefoner',
        incorrect: 'Elektronik',
      },
      {
        correct: 'Böcker, tidningar…',
        incorrect: 'Dokument',
      },
      {
        correct: 'Leksaker, smycken…',
        incorrect: 'Presenter',
      },
    ],
  },
  save: 'Spara',
  senderAndConsigneePage: {
    title: 'Avsändare & mottagare',
    tab: 'Avsändare & mottagare',
    previousStep: 'Tillbaka',
    nextStep: 'Fortsätt till sammanfattning',
    nextStepCustoms: 'Fortsätt till tullinformation',
    preamble: 'Ange information om avsändare och mottagare',
  },
  customsPage: {
    title: 'Tullinformation',
    tab: 'Tull',
    previousStep: 'Tillbaka',
    nextStep: 'Fortsätt till sammanfattning',
    preamble:
      'Ditt företag behöver ett EORI-nummer för att importera och exportera varor (EORI = Economic Operator Registration and Identification). Läs mer om EORI-nummer och hur du skaffar ett här.',
    eori: {
      linkText: 'Läs mer om Eori-nummer',
      linkUrl:
        'https://www.tullverket.se/sv/foretag/tillstandochregistreringar/eori.4.6ac2c843157b7beb007364.html',
      description: '',
      title: 'EORI',
    },
    customsValue: 'Tullvärde',
    customsValueDescription:
      'Tullvärde grundar sig på leverantörens pris för varan. Information om övriga kostnader (förutom varans pris) som ingår i tullvärdet finns att få på ',
    customsValueLinkText: 'tullverket.se',
    customsValueLinkUrl:
      'https://www.tullverket.se/sv/foretag/importeravaror/raknauttullochavgifter/tullvarde.4.7df61c5915510cfe9e7f56f.html',
    customsInvoiceNumber: 'Tullfakturanummer',
    customsInvoiceNumberDescription:
      ' En tullfaktura måste medfölja försändelsen. Om varan ska säljas i mottagarlandet använder du en handelsfaktura (commercial invoice). Om varan inte är till försäljning använder du en proformafaktura.',
    customsInvoiceNumberLinkDescription: 'Ladda ner:',
    customsInvoiceNumberLinkText1: 'Handelsfaktura',
    customsInvoiceNumberLinkUrl1:
      'https://jetpak.com/files/5714/8613/0709/Jetpak_Commercial_Invoice.pdf',
    customsInvoiceNumberLinkText2: 'Proformafaktura',
    customsInvoiceNumberLinkUrl2:
      'https://jetpak.com/files/8214/8613/0638/Jetpak_Proforma_Invoice.pdf',

    customsNumber: 'Varukod',
    customsNumberDescription1:
      ' Varukoden styr vilken tullsats som ska gälla för varan och består av ett tiosiffrigt nummer. Du kan söka varukod på Tullverkets hemsida under rubriken varukoder och klassificering.',
    customsNumberDescription2:
      'På tullfakturan anges varukoden i fältet Customs Commodity Code.',
    customsContent: '',
    shipperEori: 'Eori avsändare',
    consigneeEori: 'Eori mottagare',
    commodityCodeLength: 'Minst 6 tecken',
  },
  paymentPage: {
    title: 'Sammanfattning',
    tab: 'Sammanfattning',
    preamble: '',
    previousStep: 'Tillbaka',
    previousStepCustoms: 'Tillbaka',
    nextStepByCard: 'Fortsätt till kortbetalning',
    nextStepByInvoice: 'Betala med faktura',
    paymentOptions: 'Betalningsalternativ',
    paymentReference: 'Betalningsreferens',
    paymentReferencePlaceholder: 'T.ex. inköpsnummer eller referens',
    signIntoPayByInvoice: 'Du måste logga in för att betala med faktura.',
    invoiceNotAllowed:
      'Fakturabetalning är tyvärr inte tillåten. Kontakta kundtjänst för mer information.',
    campaignCode: 'Kampanjkod',
    campaignCodeApplied: 'Kampanjkoden tillagd',
    invalidCampaignCode: 'Ogiltig kampanjkod',
    apply: 'Applicera',
  },
  loginPage: {
    title: 'Logga in',
  },
  registerPage: {
    title: 'Skapa ett konto',
    buttonText: 'Skapa konto',
    continueButtonText: 'Fortsätt',
    previousButtonText: 'Tillbaka',
    customerTypeHeader: 'Jag/vi önskar bli',
    existingCustomerHeader: 'Har din organisation redan ett konto hos oss?',
    loginText: 'Har du redan ett konto?',
    fields: {
      existingNo: 'Ny kund',
      existingYes: 'Jag/vi är redan kund hos Jetpak',
      customerTypeCompany: 'Företagskund',
      customerTypePrivate: 'Privatkund',
      orgName: 'Företagsnamn',
      orgNumber: 'Organisationsnummer',
      vatNumber: 'Momsnummer',
      customerNumber: 'Kundnummer',
      firstName: 'Förnamn',
      lastName: 'Efternamn',
      email: 'E-post',
      cellPhone: 'Mobiltelefon',
      phone: 'Telefon',
      street: 'Gatunamn',
      streetNumber: 'Gatunummer',
      city: 'Postort',
      postalCode: 'Postnummer',
      country: 'Land',
    },
    invalidNbr: 'Inte ett giltigt kundnummer',
    nonExistantNbr: 'Hittade ingen kund med detta nummer',
  },
  orderConfirmationPage: {
    title: 'Bokningsbekräftelse',
    preamble:
      'Tack för att du har bokat med oss. Här är din bokningsbekräftelse.',
    yourBookingNumber: 'Ditt bokningsnummer (AWB)',
  },
  orderSummary: {
    title: 'Sammanfattning',
    preamble: '',
  },
  advise: {
    title: 'Boknings- och leveransbekräftelse',
    description:
      'Välj om du vill ha bokningsbekräftelse och/eller leveransnotiser på sms eller e-post',
    type: 'Typ',
    method: 'Metod',
    bookingConfirmation: 'Bokningsbekräftelse',
    deliveryConfirmation: 'Leveransbekräftelse',
    email: 'E-post',
    textMessage: 'SMS',
    addMoreNotifications: 'Lägg till fler notifikationer',
  },
  requiredField: 'Obligatoriskt fält',
  timeHasPassed: 'Tiden har passerats',
  invalidEmailAddress:
    'Kontrollera att e-postadressen följer formatet email@example.com',
  shipper: 'Avsändare',
  consignee: 'Mottagare',
  shipperAddress: 'Avsändaradress',
  consigneeAddress: 'Mottagaradress',
  shipperAddressPlaceholder: 'Sök avsändaradress',
  consigneeAddressPlaceholder: 'Sök mottagaradress',
  pickUpAndDelivery: 'Upphämtning/leverans',
  pickUp: 'Upphämtning',
  delivery: 'Leverans',
  select: 'Välj',
  selected: 'Vald',
  selectDeliveryOptions: 'Välj transportalternativ',
  price: 'Pris',
  date: 'Datum',
  earliestPickup: 'Hämtas tidigast',
  latestDelivery: 'Leverans senast',
  shippingDetails: 'Detaljer försändelse',
  time: 'Tid',
  package: 'Paket',
  add: 'Lägg till',
  addMore: 'Lägg till fler',
  remove: 'Ta bort',
  addPackage: 'Lägg till paket',
  removePackage: 'Ta bort paket',
  yourPackages: 'Dina paket',
  addMorePackages: 'Lägg till fler paket',
  whatIsTheSizeOfYourPackage: 'Hur stort är ditt paket?',
  weight: 'Vikt',
  width: 'Bredd',
  height: 'Höjd',
  length: 'Längd',
  quantity: 'Antal',
  quantityPcs: 'Antal stycken',
  pcs: 'st',
  added: 'Tillagd',
  vat: 'Moms',
  withoutVat: 'ex. moms',
  withVat: 'ink. moms',
  total: 'Total',
  discount: 'Rabatt',
  clear: 'Rensa',
  inKilogram: 'i kilogram',
  inCm: 'i cm',
  today: 'idag',
  tomorrow: 'imorgon',
  currency: 'Valuta',
  countryOfOrigin: 'Ursprungsland',
  username: 'Användarnamn',
  password: 'Lösenord',
  currentPassword: 'Nuvarande lösenord',
  newPassword: 'Nytt lösenord',
  newPassword2: 'Upprepa lösenord',
  passwordResetLinkCreated:
    'En lösenordsåterställningslänk har skickas till din e-postadress',
  signIn: 'Logga in',
  signOut: 'Logga ut',
  signedIn: 'Inloggad',
  signedOut: 'Utloggad',
  signInErrorMessage: `Vi hittade inte ditt Jetpak-konto`,
  forgotPassword: 'Glömt lösenord?',
  resetPassword: 'Återställ lösenord',
  changePassword: 'Byt lösenord',
  passwordReset: 'Ditt lösenord har återställts',
  passwordChanged: 'Ditt lösenord har ändrats',
  register: 'Skapa konto',
  loading: 'Laddar',
  company: 'Företag',
  name: 'Namn',
  phone: 'Telefon',
  messageToDriver: 'Meddelande till chaufför',
  messageToDriverExample: 'Portkod, övrig information...',
  paymentProcessing: 'Strax klar, behandlar din boking',
  invoice: 'Faktura',
  creditcard: 'Betalkort',
  creditCardHoldersName: 'Kortinnehavarens namn',
  email: 'E-postadress',
  country: 'Land',
  postalAddress: 'Postadress',
  postalCode: 'Postnummer',
  postalArea: 'Ort',
  profile: 'Användarprofil',
  open: 'Öpnna',
  close: 'Stäng',
  or: 'eller',
  help: 'Hjälp',
  helpPage: {
    getSupportTicket: 'Hämta ärendenummer',
    content1:
      'Har du problem med din bokning kan du här ta fram ett ärendenummer.',
    content2: 'Ring supporten och ange detta ärendenummer så får du hjälp.',
    supportNumber: 'Kundtjänst:',
    yourTicketNumber: 'Ditt ärendenummer är',
  },
  welcome: 'Välkommen',
  youAreloggedInAs: 'Du är inloggad som',
  carbonDioxideMessage: 'Preliminärt koldioxidutsläpp',
  createNewBooking: 'Påbörja ny bokning',
  addressBook: 'Adressbok',
  addressBookModal: {
    title: 'Adressbok',
    pickAddress: 'Välj en address',
    noSavedAddresses: 'Det finns inga sparade adresser.',
    name: 'Namn',
    street: 'Gatunamn',
    streetNumber: 'Gatunummer',
    city: 'Postort',
    postalCode: 'Postnummer',
    country: 'Land',
    newAddress: 'Ny adress',
    addressName: 'Adressnamn',
    addAnother: 'Lägg till en ytterligare',
    addressSaved: 'Adressen har sparats',
    editAddress: 'Ändra adress',
    showing: (showing, total) =>
      `Visar ${showing} av ${total} sparade adresser`,
  },
  addressValidationErrors: {
    missingStreet: 'Gatuadress saknas',
    missingStreetNumber: 'Gatunumret saknas',
    verificationError: 'Felaktig adress',
    error: 'Adressen kan inte verifieras',
  },
  orderFailed: {
    cancelled: 'Betalningen avbröts',
    error: 'Något gick fel vid orderläggningen',
    declined: 'Ditt kort godkändes inte',
    pickupMissed:
      'Tiden för upphämtning har passerats. Du måste söka nya transportalternativ.',
  },
  support: {
    needHelp: 'Behöver du hjälp?',
    contactOurSupport: 'Kontakta vår support.',
  },
  // parcelTypes: {
  //   envelope: 'Brev',
  //   bag: 'Påse',
  //   smallBox: 'Liten låda',
  //   largeBox: 'Stor låda',
  //   pallet: 'Pall',
  //   other: 'Annan',
  // },
  unitTypes: {
    kg: 'kg',
    cm: 'cm',
  },
  termsAndPolicies: {
    dangerousGoods: {
      label: 'Jag har läst och förstått',
      urlLabel: 'villkor för farligt gods',
      url: 'https://jetpak.com/se/villkor/viktig-information/farligt-gods/',
    },
    privacyPolicy: {
      label: 'Jag har läst och förstått Jetpaks',
      urlLabel: 'integritetspolicy',
      url: 'https://jetpak.com/se/villkor/sekretesspolicy/',
    },
    termsAndConditions: {
      label: 'Jag godkänner härmed Jetpaks',
      urlLabel: 'Jetpaks avtalsvillkor',
      url: 'https://jetpak.com/se/villkor/allmanna-transportvillkor/',
    },
  },
  days: {
    monday: 'Måndag',
    tuesday: 'Tisdag',
    wednesday: 'Onsdag',
    thursday: 'Torsdag',
    friday: 'Fredag',
    saturday: 'Lördag',
    sunday: 'Söndag',
  },
  insurance: {
    title: 'Försäkring',
    description:
      'För att försäkra dina varor, vänligen ange värdet för att beräkna pris. Priset kommer att läggas till din beställning.',
    labelToggle: 'Lägg till försäkring',
    labelInput: 'Försäkringsvärde',
    fee: 'Försäkringspremie',
    insurancePremium: (rate, minPrice, currency) =>
      `Försäkringspremien är ${rate}% av varuvärdet men minst ${minPrice} ${currency}. Inga kostnader för självrisk tillkommer.`,
  },
};
export default sv;
